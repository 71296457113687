@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.live-monitoring-container {
  position: relative;
}

.wrap-card-form-search-truck {
  margin-bottom: 16px;
}

.wrap-form-search-truck,
.wrap-form-search-store,
.wrap-form-search-geofence {
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .ant-form-item {
      margin-right: 0px;
    }

    .form-button-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-left: 50px;
    }
  }

  input[type="date"] {
    height: 32px;
  }

  .submit-button {
    color: white;
    background-color: #006d2e;

    &:hover {
      color: white !important;
      background-color: #409261 !important;
    }
  }
}

.action-gps-tracking {
  color: #000000;
  text-decoration: underline;
  &:hover {
    color: #000;
    text-decoration: underline;
  }
}

.truck-driver-do-type {
  text-transform: capitalize;
}

.wrap-table-truck-list {
  .phone-number {
    color: rgba(0, 0, 0, 0.85);
  }

  td span.status-badge {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    &.complete {
      background-color: #4fc626;
    }
    &.incomplete {
      background-color: #c62626;
    }
  }
}

.custom-tag-dotype {
  .ant-select-selection-overflow {
    span {
      text-transform: capitalize;
    }
  }
}

.popup-select-dotype {
  .ant-select-item-option-content {
    text-transform: capitalize;
  }
}

.warp-header-truck {
  display: flex;
  justify-content: space-between;

  p {
    font-family: "IBM Plex Sans Thai Semibold";
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}

.accordian-dashboard-collapse {
  margin-bottom: 13px;

  .ant-collapse-expand-icon {
    color: #acacac;
  }
}

.wrap-dashboard {
  text-align: center;
}

.wrap-popover-content {
  display: flex;
  height: auto;
  padding: 14px;

  .popover-second-column {
    width: 120px;
    padding-left: 14px;
  }

  .popover-first-column {
    width: 120px;
    padding-right: 14px;
  }

  p {
    font-size: 14px;
  }
}

.popover-detail-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  p.popover-detail-section-title,
  p.popover-detail-section-content {
    font-size: 14px;
    margin: 0px;
  }

  p.popover-detail-section-content {
    color: #4765f2;
  }
}

#active-marker {
  background-image: url("../../assets/images/map/active_marker.svg");
  background-size: contain;
  width: 40px;
  height: 40px;
  &.active {
    background-image: url("../../assets/images/map/focus_active_marker.svg");
  }
}

#inactive-marker {
  background-image: url("../../assets/images/map/inactive_marker.svg");
  background-size: contain;
  width: 40px;
  height: 40px;
  &.active {
    background-image: url("../../assets/images/map/focus_inactive_marker.svg");
  }
}

.popover-status-badge {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 5px;
  &.complete {
    background-color: #4fc626;
  }
  &.incomplete {
    background-color: #c62626;
  }
}

.marker-popup {
  position: absolute;
  min-width: 495px;
  top: 0;
  right: 0;
  background-color: white;
  padding: 12px;
  margin: 12px;
  border-radius: 8px;

  p {
    font-size: 14px;
    font-style: normal;
  }

  .marker-popup-select-load {
    p {
      margin-bottom: 0px;
    }
  }
}

.marker-popup-info-card {
  width: 100%;
  margin-top: 10px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;

  .info-sync-status {
    display: flex;
    align-content: center;
    align-items: center;
  }

  p {
    font-size: 14px;
    font-style: normal;
    margin-bottom: 0px;
  }
}

.marker-popup-detail-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 16px;

  p.detail-title {
    font-size: 14px;
    font-style: normal;
    font-family: IBM Plex Sans Thai Medium;
    margin-bottom: 4px;
  }

  .marker-popup-detail-section-info {
    padding: 0px 12px;

    .detail-section-info {
      display: flex;

      p {
        font-size: 14px;
        font-style: normal;
        margin-bottom: 0px;
      }
    }
  }
}

.loading-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98px;
}

.map-remark-message {
  margin: 12px 0;
  background-color: #f4f5f8;
  border: 1px solid #d9deeb;
  .ant-alert-message {
    color: #201c00;
  }
  .anticon-info-circle {
    color: #929cb3;
  }
}

.progress-status {
  color: #acacac;
}

.cannot-connect-totommap {
  height: 559px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .error-occurred {
    color: #667080;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-top: 10px;
  }
  .please-try-again {
    color: #a3a9b3;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .btn-reconnect {
    margin-top: 10px;
  }
}

.ant-btn {
  transition: none;
}

b {
  font: 16px IBM Plex Sans Thai Medium;
}

.normal-header {
  font: 16px IBM Plex Sans Thai Regular;
}

.drawer-header {
  font: $h2-font-size $h2-font-family $h1-font-weight;
}

.drawer-card {
  background: #f6f7fb;
  border-radius: 16px;

  .ant-btn.ant-btn-lg.ant-btn-icon-only {
    padding-inline: none;
  }

  .task-list-collapse {
    .ant-collapse-item .ant-collapse-expand-icon {
      height: 34.5px;
      padding-inline-end: 8px;
      color: $content-description;
    }
  }

  .title-link {
    font: $h3-font-size $h3-font-family $h3-font-weight;
    a {
      color: #000000;
    }
  }

  .title {
    font: $h3-font-size $h3-font-family $h3-font-weight;
  }

  .status-badge {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;

    &.complete {
      background-color: $border-primary-subdued;
    }
    &.incomplete {
      background-color: $content-danger-default;
    }
  }

  .description {
    color: #5c6372;
  }

  .truck-info {
    .ant-form-item {
      margin-bottom: 16px;
    }
  }

  .ant-form-item .ant-form-item-control-input-content {
    font: $h4-font-size $h4-font-family $body1-font-weight;
    color: #5c6372;
  }
  .ant-form-item .ant-form-item-label > label {
    font: $h4-font-size $h4-font-family $body1-font-weight;
  }

  .change-status-box {
    border-radius: 8px;

    .title {
      font: $WebH4;
    }
  }

  .flex-info-item {
    flex: 1 1 30%;

    @media (max-width: 1160px) {
      flex: 1 1 50%;
    }

    .ant-form-item-label {
      padding: 0;
    }

    .label {
      font: $WebCaption;
    }

    .content {
      font: $WebBody1;
      color: $content-description;
    }
  }

  /// Class name for task list
  .task-list {
    .task-list-card {
      width: 100%;
      background: $surface-static-ui-default;
      border-radius: 4px;
      border: 1px solid $border-default;

      .task-list-content {
        padding-left: 24px;
      }

      .stop-no {
        padding: 4px 8px;
        background-color: $surface-static-default3;
        border-radius: 4px;

        span {
          font: $WebH4;
        }
      }

      .store {
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px 8px;

        a {
          color: #000000;
        }

        span {
          font: $WebH3;
          font-size: 1.25rem;
          text-decoration: underline;
          cursor: pointer;
          white-space: nowrap;
        }
      }

      .store::after {
        font: $WebH3;
        font-size: 1.25rem;
        content: "-";
        padding-left: 8px;
        cursor: auto;
        text-decoration: none;
      }

      .province {
        width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;

        .dash {
          font: $WebH3;
          font-size: 1.25rem;
          color: #000;
          padding-right: 8px;
        }

        span {
          font: $WebH3;
          font-size: 1.25rem;
          color: $content-description;
          white-space: nowrap;
        }
      }

      .ant-form-item .ant-form-item-control-input-content {
        font: $h4-font-size $h4-font-family $body1-font-weight;
        color: #5c6372;
        a {
          font: $h4-font-size $h4-font-family $body1-font-weight;
          color: #5c6372;
        }
      }

      .ant-form-item {
        margin: 0px;
        margin-bottom: 16px;
      }

      .ant-form-item .ant-form-item-label > label {
        font: $h4-font-size $h4-font-family $body1-font-weight;
      }
    }

    .info {
      .title {
        font: $WebBody1;
        font-size: 1rem;
        color: $content-description;
      }

      .content {
        font: $WebBody1;
        font-size: 1rem;
        color: $content-default;
        display: flex;
        flex: wrap;

        &.truncate {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .tracking-icon-button {
      cursor: pointer;
      color: $content-primary-default;
    }
  }

  .ant-collapse-content,
  .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    padding-block: 0px;
  }

  .ant-collapse-content {
    padding-bottom: 16px;
  }

  .ant-collapse-item .ant-collapse-header {
    padding: 16px;
  }

  .ant-form-item:not(.source-select) {
    margin: 0px;
    margin-bottom: 8px !important;
  }

  .ant-tag {
    margin-inline-end: 0;
  }

  .source-select {
    p {
      font: $WebH4;
      margin-bottom: 0;
    }
  }

  .wrap-map {
    width: 100%;
    height: 528px;
    position: relative;
    border-radius: 8px;

    &.not-active {
      > div {
        opacity: 0.2;
      }
    }

    span.cannot-found-text {
      display: inline;
      position: absolute;
      left: calc(100% / 2 - 90px);
      z-index: 1;
      top: 50%;
    }
  }
}

.left-panel {
  width: 560px;
  overflow-y: auto;
}

.right-panel {
  min-width: 384px;
  height: 100%;
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  flex: 1;
  overflow-y: hidden;
  margin-left: 20px;
}

.store-map-popup-container {
  font: $WebBody1;
  display: flex;
  align-items: baseline;
}

.mapboxgl-popup-content {
  border-radius: 8px;
  padding: 8px 16px;
}

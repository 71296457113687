@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.store-master-content {
  min-height: calc(100vh - 64px);
  padding: 24px 36px;
  background: #f5f5f5;

  h1 {
    font: $WebH1;
  }

  h2 {
    font: $WebH2;
    margin-bottom: 0;
  }

  h3 {
    font: $WebH3;
  }

  h4 {
    font: $WebH4;
  }


  p {
    font: $WebBody1;
  }

  .page-header {
    h1 {
      margin-bottom: 0;
    }
  }

  .table-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 0;
    }
  }
  .ant-breadcrumb {
    font-size: 12px;
    li:last-child {
      span {
        color: $content-primary-default;
      }
    }
  }

  .store-master-table {
    .ant-table-container {
      border-radius: 8px;
      border: 1px solid $border-default;

      .ant-table-tbody > tr.ant-table-row-selected > td {
        background-color: $surface-static-ui-disabled;
      }

    }


    p {
      margin-bottom: 8px;
    }

    .show-seleted-and-total {
      font-size: 14px;
      color: $content-description;
      align-self: flex-end;
    }

    .group-action-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .send-message-button {
        display: flex;
        column-gap: 8px;
      }
    }
  }
}

.modal-create-store {
  h3 {
    font: $WebH3;
    margin: 0;
  }

  .col-form-name {
    display: flex;
    margin-top: 10px;

    .form-lastname {
      margin-left: 16px;
    }
  }

  .col-form-email {
    padding: 16px 0 0 0;
  }

  .card-section-user-role {
    margin: 12px 0 0 0;

    .form-company {
      margin: 0 0 16px 0;
    }

    .section-user-role {
      display: flex;
      justify-content: space-between;
      margin: 0 0 16px 0;

      h3 {
        margin: 0;
      }
      a {
        padding: 8px 16px;
        color: $content-primary-default;
      }
    }
  }

  .section-company-permission {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background: $core-palette-gray-5;

    h4 {
      font: $WebH4;
    }
  }
}

.form-search {
  display: flex;
  margin-top: 24px;

  .ant-form-item-row > .ant-form-item-label {
    display: flex;
    margin-bottom: 8px;

    label {
      height: fit-content;
    }
  }

  .ant-col:last-child {
    display: flex;
  }

  .ant-form-item {
    display: flex;
    align-items: flex-end;
  }

  .ant-form-item:first-child {
    padding-left: 0;
  }

  .ant-form-item:last-child {
    padding-right: 0;
  }
}

.store-master-message {
  .ant-message-custom-content.ant-message-success {
    display: flex;
    align-items: center;
  }
  .ant-message-custom-content.ant-message-error {
    display: flex;
    align-items: center;

    ul{
      list-style: none;
    }
  }
}


.upload-store-master {
  max-width: 768px;
  width: 100%;
  &.noti-success {
    .ant-notification-notice-description {
      display: none;
    }
  }
  .ant-notification-notice-description {
    margin-left: 0;
    margin-top: 16px;
    li {
      margin-bottom: 0;
      list-style-type: disc;
      &::marker {
        margin-right: 2px;
      }
    }
  }
}




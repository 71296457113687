@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.sider-custom {
  // overflow: auto;
  height: 100vh;
  position: sticky;
  inset-inline-start: 0;
  top: 0;
  bottom: 0;
  scrollbar-gutter: "stable";

  ul {
    li:first-child {
      margin-top: 0;
    }
  }

  .logo-section {
    padding: 16px;

    .toggle-icon {
      cursor: pointer;
      color: $content-primary-default;
    }
  }

  .select-company-section {
    padding: 16px;

    .company-selection {
      width: 100%;
    }

    .company-section {
      height: 42px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      border: 1px solid $border-default;
      border-radius: 8px;
      min-width: 48px;

      img {
        height: 26px;
        width: 42px;
        object-fit: contain;
      }

      &.collapsed {
        padding: 8px;

        img {
          width: 32px;
          height: 26px;
          object-fit: contain;
        }
      }
    }

    .ant-select-focused:where(.css-dev-only-do-not-override-41fcli).ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer)
      .ant-select-selector {
      box-shadow: none !important;
    }
  }

  .user-section {
    padding: 0 16px;

    .avatar {
      width: 36px;
      height: 36px;
      background-color: $core-palette-gray-10;
      color: $core-palette-gray-40;
    }

    .user-info {
      display: flex;
      flex-direction: column;

      .title {
        font: $WebBody2;
      }

      .sub-title {
        font: $WebCaption;
        color: $content-description;
      }
    }
  }

  .logout {
    padding: 0 16px 8px 16px;
    color: $content-description;

    span {
      font: $WebBody1;
    }

    .version {
      width: 100px;
      font: $WebCaption;
      color: $content-description;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: right;
    }
  }
}

.ant-layout-sider.ant-layout-sider-dark.sider-custom {
  min-width: 300px !important;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.sider-custom {
  min-width: 56px !important;
}
